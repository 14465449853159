<template>
<div v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="拼命加载中"></div>
<div class="content">
  <el-row v-if="show">
    <el-col>
      <el-result icon="success" title="授权成功" subTitle="请根据提示进行操作">
        <template #extra>
          <el-button type="primary" size="medium" @click="onClickHome">返回</el-button>
        </template>
      </el-result>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { alibaba } from 'network/rukou'
export default {
  name: 'Rukou',
  setup () {
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      show: false,
      fullscreenLoading: true
    })
    const formData = reactive({
      code: ''
    })
    const onClickHome = () => {
      router.push('/home')
    }
    onMounted(() => {
      // 授权用户登录
      formData.code = route.query.code
      alibaba(formData).then(res => {
        if (res.status === 1) {
          state.show = true
          state.fullscreenLoading = false
          window.localStorage.setItem('token', res.result.token)
          // 在vuex isLogin
          store.commit('setIsLogin', true)
          setTimeout(() => {
            router.push('/home')
          }, 500)
        } else {
          router.push('/home')
        }
      })
    })
    return {
      ...toRefs(state),
      onClickHome
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
